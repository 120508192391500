import CurrentProjectApi from '../models/projectsListComApi';
import siteCheckHttps from "../api/siteCheck/checkHttpApi";
import UserModel from "../models/userModel"
import {format, subDays, differenceInCalendarDays, parseISO,validateRange} from "../utils/customDateFormater";
import {DOMAIN_REGEX} from '../utils/regexp'
import {root, app} from '../main';
import popoverSimpleMsgStore from "../store/popoverSimpleMsqStore";

function clearDomain(text) {
    return text.replace(/^(?:http(s)?:\/\/)?(?:www\.)?/i, "").split("/")[0].toLowerCase();
}

function searchDomainInQuery(domainReg) {
    //const query = getUrlParams(window.location.search);
    const query = (new URL(window.location)).searchParams;
    const params = window.location.pathname.includes('/project/') ? window.location.pathname.split('/').filter(i => !!i) : null;
    if (params && params.length !== 0 && domainReg.test(params[params.length - 1])) {
        return clearDomain(params[params.length - 1]);
        //} else if (query.site && domainReg.test(query.site)) {
    } else if (query.has('site') && domainReg.test(query.get('site'))) {
        return clearDomain(query.get('site'));
    }
    return null;
}

export default {
    namespaced: true,
    state: {
        ...popoverSimpleMsgStore.state,
        isProjectHasDuplicate:false,
        popupChangeKwPos:false,
        popupAddProject:false,
        popupPromotionHistory:false,
        popupSemaltBacklinks:false,
        popupRankingsGrowth:false,
        popupTopRankingsGrowth:false,
        getFromLink:false,
        updateWork:{
            id:-1,
            cnt:0,
            type:0 // 0
        },
        payLinkFS:{
            type:0,
            link:''
        },
        //project page
        isDisableLoadingProjectPage: true,
        savedCurrentProject: null,
        savedProjectList: null,
        projectsCurrent: null,
        namePackage: '',
        isDuplicate:false,
        layout: {
            header: {
                enabled: true,
                element: {},
                height: 73,
            },
            sidebar: {
                enabled: true
            },
            footer: {
                enabled: true
            },
            isBottom: false,
        },

        manager: {},
        isManagerAvailable: false,
        managerPhoto: '/api/v1/user/manager-avatar/',

        user: {
            //authInterval: null,
            openedSocialAuthWindow: null,
            //socialAuthLink: '',
            dialog: {
                authTabs: 'SignIn',
                open: false,
            },
            login: false,
            data: null,
            session: null,
            defaultScopes: ['id', 'email', 'name', 'picture', 'register_date', 'phone', 'oauth_provider',
                'manager', 'balance', 'status', 'google_webmaster', 'google_drive', 'google_tag', 'google_analytics', 'limits',
                'token', 'lang', 'visited', 'reg']
        },
        firstLoad: true,
        customPopupSettings: null,
        availableLanguages: '',
        projectPlan: '',
        metaTags: {meta: '', title: ''},
        noPhone: false,
        lastVisited: false,
        newNativeRegistration: false,
        dialogAuth: false,
        footerItems: [],
        requestPopupOpened: false,
        requiresAuthList: []
    },
    mutations: {
        ...popoverSimpleMsgStore.mutations,
        //------currentProjSET_PROJECTS_CURRENT
        SET_IS_PROJECT_HAS_DUPLICATE(state, value){
            state.isProjectHasDuplicate = value;
        },
        SET_POPUP_ADD_PROJECT(state, data){
            state.popupAddProject = data;
        },
        SET_POPUP_CHANGE_KW_POS(state, data){
            state.popupChangeKwPos = data;
        },
        SET_GET_FROM_LINK(state, value) {
            state.getFromLink = value
        },
        SET_POPUP_PROMOTION_HISTORY(state, data){
            state.popupPromotionHistory = data;
        },
        SET_POPUP_SEMALT_BACKLINKS(state, data){
            state.popupSemaltBacklinks = data;
        },
        SET_POPUP_RANKINGS_GROWTH(state, data){
            state.popupRankingsGrowth = data;
        },
        SET_POPUP_TOP_RANKINGS_GROWTH(state, data){
            state.popupTopRankingsGrowth = data;
        },
        SET_UPDATE_WORK(state, data) {
            state.updateWork = data
        },
        SET_TYPE_AND_HREF_PAY_LINK_FS(state, data) {
            state.payLinkFS = data
        },
        SET_IS_LOADING_PP(state, value) {
            state.isDisableLoadingProjectPage = value
        },
        SET_PROJECTS_CURRENT_VALID(state, data) {
            state.projectsCurrent.valid = data;
        },
        SET_PROJECTS_CURRENT(state, data) {
            state.projectsCurrent = data;
        },
        SET_PROJECTS_CURRENT_INFO(state, data) {
            state.projectsCurrent.info = data;
        },
        SET_PROJECTS_CURRENT_ARCHIVED(state, data) {
            state.projectsCurrent.archived = data;
        },
        SET_PROJECTS_CURRENT_NAME_PACKAGE(state, data) {
            state.projectsCurrent.namePackage = data;
        },
        SET_PROJECTS_CURRENT_IS_DUPLICATE(state, value) {
            state.projectsCurrent.isDuplicate = value;
        },
        SAVE_CURRENT_PROJECT(state, data) {
            state.savedCurrentProject = data;
        },
        //------end currentProject mutations
        SAVE_PROJECTS_LIST(state, data) {
            state.savedProjectList = data;
        },

        //------ManagerData mutations
        SET_MANAGER(state, payload) {
            state.manager = payload
        },
        SET_MANAGER_PHOTO(state, payload) {
            state.managerPhoto = payload
        },
        SET_MANAGER_AVAILABLE(state, payload) {
            state.isManagerAvailable = payload
        },
        //------end ManagerData mutations

        SET_LOGIN(state, data) {
            state.user.login = data
        },
        // SET_SCOPE(state, {scope, data}) {
        //   Vue.set(state.user.data, scope, data)
        // },
        SET_SCOPE(state, {scope, data}) {
            state.user.data[scope] = data;
        },
        SET_SCOPES(state, data) {
            state.user.data = data
        },
        SET_DIALOG(state, data) {
            state.user.dialog.open = data
        },
        SET_AUTH_TABS(state, data) {
            state.user.dialog.authTabs = data
        },
        SET_OPENED_SOCIAL_AUTH_WINDOW(state, data) {
            state.user.openedSocialAuthWindow = data
        },
        SET_CUSTOM_POPUP_SETTINGS(state, data) {
            state.customPopupSettings = data
        },
        SET_AVAILABLE_LANGUAGES(state, data) {
            state.availableLanguages = data
        },
        SET_PROJECT_PLAN(state, data) {
            state.projectPlan = data
        },
        SET_META_CUSTOM(state, data) {
            state.metaTags = data
        },
        SET_NO_PHONE(state, data) {
            state.noPhone = data
        },
        SET_LAST_VISITED(state, data) {
            state.lastVisited = data
        },
        SET_NATIVE_REGISTRATION(state, data) {
            state.newNativeRegistration = data
        },
        SET_DIALOG_AUTH(state, data) {
            state.dialogAuth = data
        },
        SET_FOOTER_ITEMS(state, data) {
            state.footerItems = data
        },
        SET_REQUEST_POPUP_OPENED(state, data) {
            state.requestPopupOpened = data
        },
        SET_RA_LIST(state, data) {
            state.requiresAuthList = data;
        }
    },
    actions: {


        ...popoverSimpleMsgStore.actions,
        async setLoadTableAndTop(){
            // await dispatch('setLoadTableAndTop');
            // this.commit('mainStore/SET_IS_LOADING_PP', false);
            // await this.commit('keywords/SET_FETCH_STATUS', true);
            // await this.commit('keywords/SET_TABLE_LOADING', true)
        },
        async getHttsForProject({getters,commit}){
            if(getters.currentProject){
                let projectHttps = await siteCheckHttps.check(getters.currentProject.site);
                if(projectHttps.data.result){
                    commit('SET_PROJECTS_CURRENT_VALID', projectHttps.data.result);
                }
            }

        },
        async getProjectInfoForPage({getters, dispatch, commit, rootState}) {
            if (root.user.id > 0) commit('SET_LOGIN', true);
            await dispatch('getHttsForProject');
            if (getters.isLogin) {
                // get info API

                const infoProject = await dispatch('projectStore/projectInfoLoad', {ids: getters.currentProject.id}, {root: true});

                commit('SET_PROJECTS_CURRENT_INFO', infoProject);


                if(infoProject && infoProject.result && (infoProject.result.group_default <=0 || infoProject.result.group_default >=0)){
                    this.commit('keywords/SET_GROUP_ID',infoProject.result.group_default);
                    if(infoProject.result.group_default >=0){
                        this.commit('keywords/SET_TOP','All rankings');
                    }

                }
                // get archived API
                const archivedProject = await dispatch('projectStore/projectArchivedLoad', {
                    ids: getters.currentProject.id,
                    domain: getters.currentProject.site,
                    se: getters.currentProject.se,
                    dateStart: getters.currentProject.info.result.seostart,
                    dateEnd:getters.currentProject.info.result.seoend,
                    mt: getters.currentProject.mt ? getters.currentProject.mt : 2,
                    fullseo: getters.currentProject.info.result.package == 'fullseo' ? 1 : 0
                }, {root: true});

                commit('SET_PROJECTS_CURRENT_ARCHIVED', archivedProject);
                commit('SET_PROJECTS_CURRENT_NAME_PACKAGE', archivedProject.seotext);


            }
        },
        //------currentProject actions
        async checkQueryAndSetCurrentProject({getters, dispatch, commit, rootState, state}) {

            commit('SET_IS_LOADING_PP', true);
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            if((params.get('from') && params.get('from') == 'link')){
                commit('SET_GET_FROM_LINK', true);
            }

            if (!getters.currentProject || !getters.currentProject.site) {
                const domainFromQuery = await searchDomainInQuery(DOMAIN_REGEX);

                const urlParams = new URLSearchParams(window.location.search);
                const idProjectInQuery = urlParams.get('idp');
                const seInQuery = urlParams.get('se');
                if (idProjectInQuery) {
                    const infoProject = await dispatch('projectStore/projectInfoLoad', {ids: idProjectInQuery}, {root: true});

                    let projectToSet = {
                        'id': infoProject.result.id,
                        'site': infoProject.result.site,
                        'se': infoProject.result.se,
                        'se2': infoProject.result.se2,
                        'favicon': '',
                        'mt': 1
                    }
                    commit('SET_PROJECTS_CURRENT', projectToSet);
                }else if (domainFromQuery && seInQuery) {
                    let projectWithDomain, projectWithDomainAndSe;
                    projectWithDomain = getters.projectsList.filter(item => item.site === domainFromQuery);

                    if (projectWithDomain.length < 1 && domainFromQuery){
                        const result = await dispatch('projectStore/projectsListAdd', {
                            projects: [{
                                site: domainFromQuery,
                                tags: []
                            }], pushToList: false
                        }, {root: true});

                        if(seInQuery !== result[0].se && root.user.id ==0){
                            const resultNew = await dispatch('projectStore/projectsListAddBySe', {page:domainFromQuery,se:seInQuery}, {root: true});

                            if (!resultNew) {
                                return;
                            } else {
                                let projectToSet = {
                                    'id': resultNew.id,
                                    'site': resultNew.site,
                                    'se': resultNew.se,
                                    'se2': 1,
                                    'favicon': '',
                                    'mt': resultNew.mt,
                                    'login': 0,
                                    'page': resultNew.pages
                                }

                                commit('SET_PROJECTS_CURRENT', projectToSet);
                            }

                            return;
                        }

                        commit('SET_PROJECTS_CURRENT', result[0]);
                        await dispatch('getProjectInfoForPage');
                        await dispatch('saveCurrentProjectDates');
                        return;
                    }
                    projectWithDomainAndSe = projectWithDomain[0].pages.filter(item => item.site === domainFromQuery && item.se == seInQuery);
                    const result = await dispatch('projectStore/projectsListAddBySe', {page:domainFromQuery,se:seInQuery}, {root: true});

                    if (!result) {
                        return;
                    } else {
                        let projectToSet = {
                            'id': result.id,
                            'site': result.site,
                            'se': result.se,
                            'se2': 1,
                            'favicon': '',
                            'mt': result.mt,
                            'login': 0,
                            'page': result.pages
                        }

                        commit('SET_PROJECTS_CURRENT', projectToSet);
                    }

                } else if (domainFromQuery && !seInQuery) {

                    const result = await dispatch('projectStore/projectsListAdd', {
                        projects: [{
                            site: domainFromQuery,
                            tags: []
                        }], pushToList: false
                    }, {root: true});
                    commit('SET_PROJECTS_CURRENT', result[0]);

                }else {
                    const firstProject = await dispatch('projectStore/projectsListLoadFirstOne', null, {root: true});
                    commit('SET_PROJECTS_CURRENT', firstProject);
                }


            }


            //if 0 projects in acc
            if (!getters.currentProject || !getters.currentProject.site){

                this.commit('keywords/SET_FETCH_STATUS',false);
                commit('SET_IS_LOADING_PP', false); return;
            }

            await dispatch('getProjectInfoForPage');
            await dispatch('saveCurrentProjectDates');


        },

        async getSeoDatesFromRoot({rootState}, paramsQuery){
            let rootProjects = root.uinfo.projects;
            let siteQuery = paramsQuery.get('site');
            let res = [];
            let active = 0;
            if(rootProjects.autoseo){
                res = Object.entries(rootProjects.autoseo).filter(e => e[1].site==siteQuery).map(e => e[1]);
                if(res[0]){
                    active = res[0].active;
                }
            }
            if(rootProjects.fullseo){
                res = Object.entries(rootProjects.fullseo).filter(e => e[1].site==siteQuery).map(e => e[1]);
                if(res[0]){
                    if (active > 0 && res[0].active == 0){
                        res = Object.entries(rootProjects.autoseo).filter(e => e[1].site==siteQuery).map(e => e[1]);
                    }
                }
            }

            return res.length > 0 ?  res[0] : {};

        },

        async saveCurrentProjectDates({state, rootState, getters, commit,dispatch}) {
            if (rootState.settings && getters.isLogin) {
                let url = new URL(window.location.href)
                let params = new URLSearchParams(url.search);

                if(state.getFromLink && params.get('range') && validateRange(params.get('range'))){
                    commit('SET_IS_LOADING_PP', false);
                    return;
                }
                else if(state.getFromLink){

                    this.commit('settings/SET_CURRENT_DATES', {
                        start: format(subDays(new Date(), 6), 'yyyy-MM-dd',{utc:true}),
                        end: format(rootState.mainStore.projectsCurrent.info.today, 'yyyy-MM-dd',{utc:true})
                    });
                    this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                    commit('SET_IS_LOADING_PP', false);
                    return;
                }

                let seoData = await dispatch('getSeoDatesFromRoot',params);

                if (seoData.start == null || (rootState.mainStore.isProjectHasDuplicate && seoData.start == null)) {
                    this.commit('settings/SET_CURRENT_DATES', {
                        start: format(subDays(new Date(), 6), 'yyyy-MM-dd',{utc:true}),
                        end: format(root.today, 'yyyy-MM-dd',{utc:true})
                    });
                    this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);

                }
                else {
                    let siteFromParam = params.get('site') // 'chrome-instant'
                    if ((rootState.settings.currentDates.start.length !=0 && rootState.settings.currentDates.end.length!=0)&&
                        rootState.mainStore.projectsCurrent.site == siteFromParam && rootState.mainStore.projectsCurrent.se ==  params.get('se')
                    ) {

                        if ((seoData.end && differenceInCalendarDays(parseISO(root.today), parseISO(seoData.end)) > 8)
                            || (rootState.settings.currentDates.start > seoData.end > rootState.settings.currentDates.end)
                        ) {
                            this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                        } else {

                            this.commit('project/SET_SCALE_PROJECT_COMPARE', 2);
                        }

                    } else {
                        //add data after 8 day
                        if ((seoData.end && differenceInCalendarDays(parseISO(root.today), parseISO(seoData.end)) > 8)
                            || (rootState.settings.currentDates.start > seoData.end > rootState.settings.currentDates.end)
                        ) {

                            this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                        } else {

                            this.commit('project/SET_SCALE_PROJECT_COMPARE', 2);
                        }

                    }
                    this.commit('settings/SET_CURRENT_DATES', {
                        start: format(seoData.start, 'yyyy-MM-dd',{utc:true}),
                        end: format(root.today, 'yyyy-MM-dd',{utc:true})
                    });

                }

                // if (rootState.mainStore.projectsCurrent.info.result.seostart == null || (rootState.mainStore.isProjectHasDuplicate && rootState.mainStore.projectsCurrent.info.result.seostart == null)) {
                //
                //     this.commit('settings/SET_CURRENT_DATES', {
                //         start: format(subDays(new Date(), 6), 'yyyy-MM-dd',{utc:true}),
                //         end: format(rootState.mainStore.projectsCurrent.info.today, 'yyyy-MM-dd',{utc:true})
                //     });
                //     this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                //
                // }
                // else {
                //
                //     let siteFromParam = params.get('site') // 'chrome-instant'
                //     if ((rootState.settings.currentDates.start.length !=0 && rootState.settings.currentDates.end.length!=0)&&
                //         rootState.mainStore.projectsCurrent.site == siteFromParam && rootState.mainStore.projectsCurrent.se ==  params.get('se')
                //         ) {
                //         if ((rootState.mainStore.projectsCurrent.info.result.seoend && differenceInCalendarDays(parseISO(rootState.mainStore.projectsCurrent.info.today), parseISO(rootState.mainStore.projectsCurrent.info.result.seoend)) > 8)
                //             || (rootState.settings.currentDates.start > rootState.mainStore.projectsCurrent.info.result.seoend > rootState.settings.currentDates.end)
                //         ) {
                //             this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                //         } else {
                //             this.commit('project/SET_SCALE_PROJECT_COMPARE', 2);
                //         }
                //     } else {
                //         //add data after 8 day
                //         if ((rootState.mainStore.projectsCurrent.info.result.seoend && differenceInCalendarDays(parseISO(rootState.mainStore.projectsCurrent.info.today), parseISO(rootState.mainStore.projectsCurrent.info.result.seoend)) > 8)
                //             || (rootState.settings.currentDates.start > rootState.mainStore.projectsCurrent.info.result.seoend > rootState.settings.currentDates.end)
                //         ) {
                //
                //             this.commit('project/SET_SCALE_PROJECT_COMPARE', 1);
                //         } else {
                //             this.commit('project/SET_SCALE_PROJECT_COMPARE', 2);
                //         }
                //     }
                //     this.commit('settings/SET_CURRENT_DATES', {
                //         start: format(rootState.mainStore.projectsCurrent.info.result.seostart, 'yyyy-MM-dd',{utc:true}),
                //         end: format(rootState.mainStore.projectsCurrent.info.today, 'yyyy-MM-dd',{utc:true})
                //     });
                // }


                commit('SET_IS_LOADING_PP', false);
            }

        },

        async saveCurrentProject({commit, getters}) {
            commit('SAVE_CURRENT_PROJECT', getters.currentProject);
        },
        saveCurrentProjectsList({commit, getters, rootState}) {
            //commit('SAVE_PROJECTS_LIST', rootState['projectStore'].list.length > 0 ? rootState['projectStore'].list : null);
            const list = getters['projectStore'];
            commit('SAVE_PROJECTS_LIST', list.length > 0 ? list : null);
        },
        async checkSavedAndSetCurrentProject({state, dispatch, commit}) {

            if (state.savedCurrentProject) {
                await dispatch('setCurrentProject', {site: state.savedCurrentProject.site, fromSaved: true});
                commit('SAVE_CURRENT_PROJECT', null);
            } else {
                const firstProject = await dispatch('projectStore/projectsListLoadFirstOne', null, {root: true});
                await dispatch('setCurrentProject', firstProject);
            }

        },
        /**
         * Sets the current project from list. If project is not in list - adds project to list automatically.
         *
         * @param context
         * @param {String|Object} project
         */
        async setCurrentProject({state, getters, commit, dispatch}, project) {
            try {
                const domain = typeof project === 'string' ? clearDomain(project) : typeof project === 'object' && project ? project.site : null;
                if (!domain) {
                    throw 'Empty domain';
                } else {
                    //let projectToSet = dispatch('projectStore/projectsListGetProject', domain, {root: true});
                    //const projectToSet = await dispatch('getProject', domain);
                    const projectToSet = project.fromSaved ? await dispatch('projectStore/projectsListGetByDomain', domain, {root: true}) : await dispatch('getProject', domain);
                    if (!projectToSet || (projectToSet && !projectToSet.id)) {
                        await dispatch('addProjects', {projects: [{site: domain, tags: []}], setCurrent: true});
                    } else {
                        commit('SET_PROJECTS_CURRENT', projectToSet);
                        await dispatch('getProjectInfoForPage');
                        await dispatch('saveCurrentProjectDates');
                    }
                }
            } catch (err) {
                commit('SET_PROJECTS_CURRENT', null);
                //console.error(err);
            }

        },
        async setCurrentProjectFromPages({state, getters, commit, dispatch}, pages) {
            if (pages) {
                let projectToSet = {
                    'id': pages.id_not_uniq || pages.id,
                    'site': pages.site,
                    'se': pages.se,
                    'se2': pages.se2,
                    'favicon': pages.favicon,
                    'mt': pages.mt,
                    'login': pages.login,
                    'page': pages.page
                }

                commit('SET_PROJECTS_CURRENT', projectToSet);
                await dispatch('getProjectInfoForPage');
                await dispatch('saveCurrentProjectDates');
            }
        },
        async setCurrentProjectFromSeFilter({state, getters, commit, dispatch}, pages) {
            const result = await dispatch('projectStore/projectsListAddBySe', pages, {root: true});
            if (!result) {
                return;
            } else {
                let projectToSet = {
                    'id': result.id,
                    'site': result.site,
                    'se': result.se,
                    'se2': 1,
                    'favicon': '',
                    'mt': result.mt,
                    'login': 0,
                    'page': result.pages
                }

                commit('SET_PROJECTS_CURRENT', projectToSet);
                await dispatch('getProjectInfoForPage');
                await dispatch('saveCurrentProjectDates');
            }

        },

        async addProjectFromSeFilter({state, getters, commit, dispatch}, pages) {
            const result = await dispatch('projectStore/projectsListAddBySe', pages, {root: true});
            if (!result) {
                return;
            } else {
                let projectToSet = {
                    'id': result.id,
                    'site': result.site,
                    'se': result.se,
                    'se2': 1,
                    'favicon': '',
                    'mt': result.mt,
                    'login': 0,
                    'page': result.pages
                };

                await CurrentProjectApi.move({
                    id: projectToSet.id,
                    url:projectToSet.site,
                }).then(async (res) => {
                    if(res && res.data.result) {
                        window.location.href = '/project?site='+projectToSet.site+'&se='+ res.data.se;
                        projectToSet.se = res.data.se;
                       // commit('SET_PROJECTS_CURRENT', projectToSet);
                      //  await dispatch('getProjectInfoForPage');
                      //  await dispatch('saveCurrentProjectDates');
                    }
                })
            }

        },

        async setCurrentProjGlob({state}) {
            if (state.projectsCurrent && state.projectsCurrent.site) {
                CurrentProjectApi.setCurrent(state.projectsCurrent.site)
            }
        },

        async setCurrentProjectRankings({state, getters, commit, dispatch}, project) {

            try {
                const domain = typeof project === 'string' ? clearDomain(project) : typeof project === 'object' && project ? project.site : null;
                if (!domain) {
                    throw 'Empty domain';
                } else {
                        commit('SET_PROJECTS_CURRENT', project);
                        // get info API
                        const infoProject = await dispatch('projectStore/projectInfoLoad', {ids: getters.currentProject.id}, {root: true});
                        commit('SET_PROJECTS_CURRENT_INFO', infoProject);
                        if (infoProject && infoProject.result && (infoProject.result.group_default <= 0 || infoProject.result.group_default >= 0)) {
                            this.commit('keywords/SET_GROUP_ID', infoProject.result.group_default);
                            if (infoProject.result.group_default >= 0) {
                                this.commit('keywords/SET_TOP', 'All rankings');
                            }
                        }
                        await dispatch('saveCurrentProjectDates');



                }
            } catch (err) {
                commit('SET_PROJECTS_CURRENT', null);
                //console.error(err);
            }

        },

        //------end currentProject actions

        //------ProjectsList actions
        /**
         * Load projects from User. If not logged in - just counts projects in storage.
         * If query has site property - this site will be set as current project, else first in list.
         *
         * @param context
         */
        async loadProjects({state, commit, dispatch, rootState}) {
            await dispatch('projectStore/projectsListLoad', false, {root: true});
            if (state.savedProjectList) {
                const projectsToSet = [];
                state.savedProjectList.forEach((proj) => {
                    const np = rootState['projectStore'].projectsList.find(el => el.id === proj.id);
                    if (!np) {
                        projectsToSet.push[proj];
                    }
                });
                commit('SAVE_PROJECTS_LIST', null);
                if (projectsToSet.length > 0) {
                    await dispatch('addProjects', {projects: projectsToSet});
                }
            }
        },
        async fetchProjectsSort({dispatch}, payload) {
            await dispatch('projectStore/projectsListFetchSort', payload, {root: true});
        },
        async resetProjects({commit, dispatch, getters, state}, action) {
            if (action === 'logout') {
                commit('projectStore/SET_PR_LIST', [state.projectsCurrent], {root: true});
                commit('projectStore/SET_PR_LOADED', 1, {root: true});
                commit('projectStore/SET_PR_TOTAL', 1, {root: true});
                commit('projectStore/SET_PR_OFFSET', 0, {root: true});
                commit('projectStore/SET_PR_IS_LOADING', false, {root: true});

                await dispatch('setCurrentProject', state.projectsCurrent);
            } else {
                await dispatch('projectStore/projectsListStateReset', null, {root: true});
                await dispatch('setCurrentProject', null);
            }
        },
        /**
         * Get project by ID or site url.
         *
         * @param context
         * @param {String|Number} id - project.site | project.id
         * @return {Promise<resolved>} - project {Object}
         * @return {Promise<rejected>} - Error event {Error}
         */
        getProject({dispatch}, id) {
            return dispatch('projectStore/projectsListGetProject', id, {root: true});
        },
        /**
         * Get full list of projects from storage.
         *
         * @param context
         * @return {Promise<resolved>} - projects list {Array.<Object>}
         * @return {Promise<rejected>} - Error event {Error}
         */
        async getProjectsList({rootState}) {
            try {
                return Promise.resolve(rootState['projectStore'].projectsList || [])
            } catch (e) {
                return Promise.reject(e)
            }
        },
        /**
         * Adds project(s) to User and/or storage. If logged in - adds project(s) to User automatically.
         *
         * @param context
         * @param {Array.<Object>|Object} projects - {site: example.com}
         * @param {Boolean} setCurrent - set first project to current
         */
        async addProjects({state, getters, commit, dispatch, rootState}, {projects, setCurrent = false}) {
            const saveTotal = rootState['projectStore'].projectsTotal;
            const result = await dispatch('projectStore/projectsListAdd', {projects}, {root: true});
            if (!result) {
                return;
            }
            if (setCurrent || !saveTotal) {
                await dispatch('setCurrentProject', result[0])
            }

            //localStorage.setItem('tabAction', JSON.stringify({type: 'reload'}))
            localStorage.setItem('add', JSON.stringify(result));
            localStorage.setItem('tabAction', JSON.stringify({type: 'add'}));

            return Promise.resolve(result);
        },
        /**
         * Delete project(s) from User and/or storage. If logged in - project is only deleted after request.
         *
         * @param context
         * @param {Array.<String>|String} sites - sites to delete
         * @param {Boolean} blockReq - force block delete request
         */
        async deleteProjects({getters, commit, dispatch, rootState}, {sites}) {
            try {
                const sitesFormatted = await dispatch('projectStore/projectsListDelete', {sites: sites}, {root: true});

                if (getters.currentProject && sitesFormatted.indexOf(getters.currentProject.site) !== -1) {
                    commit('SET_PROJECTS_CURRENT', rootState['projectStore'].projectsList[0] || null)
                }

                //localStorage.setItem('tabAction', JSON.stringify({type: 'reload'}))
                localStorage.setItem('delete', JSON.stringify(sitesFormatted));
                localStorage.setItem('tabAction', JSON.stringify({type: 'delete'}))
            } catch (err) {
                //console.error(err);
                dispatch('app/showErrorSnackbar', null, {root: true});
            }
        },
        setProjectsLoading({commit}, val) {
            commit('projectStore/SET_PR_IS_LOADING', val, {root: true});
        },
        //------end ProjectsList actions

        async getSeByIDs({dispatch, rootState}, ids = []) {
            const result = rootState['seStore'] ? await dispatch('seStore/getSeByIDs', ids, {root: true}) : [];
            return Promise.resolve(result);
        },

        //User
        async login({state, commit}) {
            let success = false;
            let user = {};
            UserModel.getServices('active_langs,plan').then(
                (response) => {
                    if (response.data.result.active_langs) {
                        commit('SET_AVAILABLE_LANGUAGES', response.data.result.active_langs);
                    }
                    if (response.data.result.plan) {
                        commit('SET_PROJECT_PLAN', response.data.result.plan)
                    }
                }
            ).catch((error) => {
                //console.error(error);
            });

            try {
                ({data: user} = await UserModel.get(state.user.defaultScopes.join()));
            } catch (e) {
                //console.error(e);
                user.error = e;
            }
            if (!user.error && user.result) {
                success = true;
                commit('SET_LOGIN', true);
                commit('SET_SCOPES', {
                    ...user.result,
                    pictureSrc: user.result.picture ? `${process.env.VUE_APP_API}/api/v1/user/get-avatar/?user_id=${user.result.id}` : ''
                });
                commit('SET_NO_PHONE', user.result.phone === '');
                commit('SET_LAST_VISITED', user.result.visited);
                commit('SET_NATIVE_REGISTRATION', user.result.reg);

                UserModel.getUserCustomPopups().then(
                    (response) => {
                        if (response.data.result) {
                            commit('SET_CUSTOM_POPUP_SETTINGS', response.data.result.length > 0 ? response.data.result[0] : null);
                        } else {
                            commit('SET_CUSTOM_POPUP_SETTINGS', null);
                        }
                    }
                ).catch((error) => {
                    //console.error(error);
                    commit('SET_CUSTOM_POPUP_SETTINGS', null);
                });
            }

            return Promise.resolve(success)
        },
        logout({commit, getters}) {
            if (getters.isLogin) {
                commit('SET_SCOPES', null);
                commit('SET_LOGIN', false);
                UserModel.logout();
                localStorage.clear();
                sessionStorage.clear();
            }
        },
        async getScope({state, commit, getters}, {scope = 'name', force = false}) {
            if (getters.isLogin && typeof scope === 'string') {
                let scopeData = state.user.data[scope];

                if (!scopeData || force) {
                    let fetchedScope;

                    try {
                        ({data: fetchedScope} = await UserModel.get(scope))
                    } catch (e) {
                        return Promise.reject(e)
                    }

                    if (fetchedScope) {
                        scopeData = fetchedScope.result[scope];
                        commit('SET_SCOPE', {scope, data: scopeData})
                    }
                }

                return Promise.resolve(scopeData)
            }
        },
        async getScopes({state, commit, getters}, {scopes = state.user.defaultScopes, force = false}) {
            if (getters.isLogin && scopes) {
                const needleScopes = [];
                const resultObject = {};

                for (const scope of scopes) {
                    if (!state.user.data[scope] || force) {
                        needleScopes.push(scope)
                    }
                }

                if (needleScopes && needleScopes.length > 0) {
                    let fetchedScopes;

                    try {
                        ({data: fetchedScopes} = await UserModel.get(needleScopes.join(',')))
                    } catch (e) {
                        return Promise.reject(e)
                    }

                    if (fetchedScopes) {
                        Object.entries(fetchedScopes.result).forEach((scope) => {
                            commit('SET_SCOPE', {scope: scope[0], data: scope[1]})
                        })
                    }
                }

                for (const scope of scopes) {
                    resultObject[scope] = state.user.data[scope]
                }

                return Promise.resolve(resultObject)
            }
        },

        closeDialog({state, commit}) {
            if (state.user.dialog.open) {
                commit('SET_DIALOG', false);
                commit('SET_AUTH_TABS', 'SignIn')
            }
        },
        openDialog({commit}, props) {
            if (props && props.tab) {
                commit('SET_AUTH_TABS', props.tab);
            }
            commit('SET_DIALOG', true)
        },
        async getSocialLink({commit, dispatch}, {service, group, redirectURI, redirect}) {
            try {
                const {data: {result: link}} =
                    group == null
                        ?
                        await UserModel.socialAuthLink(service, redirect)
                        :
                        await UserModel.socialAuthLinkPermission(service, group, redirectURI, redirect);

                //commit('SET_SOCIAL_AUTH_LINK', link)
                return Promise.resolve(link);
            } catch (error) {
                //console.error(error)
                dispatch('app/showErrorSnackbar', null, {root: true});
            }
        },
        async openSocialAuthWindow({state, commit, dispatch, getters}, {service, link, group = null, redirectURI = '/', redirect = false, router}) {
            const width = 650;
            const height = 450;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2;
            let socialAuthLink = link;

            //Clear previous window data if it wasn`t closed
            //commit('CLEAR_AUTH_INTERVAL');
            //commit('SET_AUTH_INTERVAL', null);

            // if(state.user.openedSocialAuthWindow){
            //   // if(!state.user.openedSocialAuthWindow.closed) {
            //   //   state.user.openedSocialAuthWindow.close();
            //   // }
            //   commit('SET_OPENED_SOCIAL_AUTH_WINDOW', null)
            // }
            //document.removeEventListener('mousedown', handler);

            if (!link) {
                socialAuthLink = await dispatch('getSocialLink', {service, group, redirectURI, redirect});
            }
            const dialog = window.open(socialAuthLink, `${service}AuthWindow`, [
                `width=${width}`,
                `height=${height}`,
                `left=${left}`,
                `top=${top}`,
                'status=no',
                'resizable=yes',
                'toolbar=no',
                'menubar=no',
                'scrollbars=yes'
            ].join(','));
            //commit('SET_OPENED_SOCIAL_AUTH_WINDOW',  dialog);

            // const handler = (e) => {
            //   if(state.user.authInterval){
            //     commit('CLEAR_AUTH_INTERVAL');
            //     commit('SET_AUTH_INTERVAL', null)
            //   }
            //   if(state.user.openedSocialAuthWindow){
            //     state.user.openedSocialAuthWindow.close();
            //     commit('SET_OPENED_SOCIAL_AUTH_WINDOW', null)
            //   }
            //   document.removeEventListener('mousedown', handler);
            // };
            // document.addEventListener('mousedown', handler);

            const interval = setInterval(async () => {
                if (dialog.closed) {
                    clearInterval(interval);
                    const res = await UserModel.get('id');
                    if (!res.data || res.data.error || !res.data.result) {
                        return;
                    }
                    try {
                        commit('SET_DIALOG', false);
                    } catch (e) {
                    }
                    if (res.data.result.id !== getters.userId) {
                        const isLogin = await dispatch('login');
                        if (isLogin) {
                            //await dispatch('resetProjects');
                            dispatch('checkSavedAndSetCurrentProject');
                            await dispatch('loadProjects');
                            if (group) {
                                await dispatch('getScope', {scope: group, force: true});
                                router ? router.push(state.user.data[group].length ? redirectURI : '/') : ''
                            }
                        }
                    }
                } else if (!state.user.dialog.open) {
                    clearInterval(interval);
                    dialog.close();
                }
            }, 1000);
        },

    },
    getters: {
        isDisableLoadingProjectPage(state) {
            //console.log(state.topsIsFetch || state.dynamicsChartLoading);
            return state.isDisableLoadingProjectPage;
        },
        headerHeight(state) {
            return state.layout.header.height;
        },
        projectsList(state, getters, rootState) {
            return rootState['projectStore'].projectsList || []
        },
        /**
         * Get current project object.
         *
         * @return {Object|null}
         */
        currentProject(state) {
            return state.projectsCurrent && state.projectsCurrent.site ? state.projectsCurrent : null
        },
        /**
         * Get count of projects in DB.
         *
         * @return {Number}
         */
        projectsLoaded(state, getters, rootState) {
            return rootState['projectStore'].projectsLoaded
        },
        /**
         * Get count of projects in User. If not logged in returns count of projects in DB.
         *
         * @return {Number}
         */
        projectsTotal(state, getters, rootState) {
            return rootState['projectStore'].projectsTotal
        },
        isLoadingProjects(state, getters, rootState) {
            return rootState['projectStore'].projectsIsLoading
        },
        isLogin(state) {
            return state.user.login
        },
        userId(state) {
            return state.user.data ? state.user.data.id : -1 || -1
        },
        userData(state) {
            return state.user.data || null
        },
        // getDefaultLangMiniList (state) {
        //   return state.defaultLangList.map(item => item.code)
        // },
        getPhonePopupOpened(state) {
            return state.noPhone && state.lastVisited || state.noPhone && state.newNativeRegistration
        },
    },
}